<template>
  <section class="w-100 VendorProducts">
    <div class="row mb-3">
      <div class="col-12 d-flex back-button align-items-center" @click="$emit('backFromVariant','')" style="cursor:pointer">
        <i class="fa-solid mr-2 fa-chevron-left"></i>
        <div>Back to Tags</div>
      </div>
    </div>
    <div class=" row questions-not-font flex-column d-flex justify-content-center align-items-center"
      v-if="areNotAvailableProducts">
      <img src="https://www.quizell.com/_nuxt/img/why_quizell_male.04fc678.svg" alt="Svg" style="width: 15rem;">
      <div class="row text-center px-2">
        <div class="col-12">
          <h3>Products Not Available</h3>
        </div>
       
      </div>

    </div>

    <b-skeleton type="input" v-else-if=isLoadingProducts></b-skeleton>
    <div class="" v-else>
      <div class="collectionSearchInput col-12 row align-items-center mx-1 pr-3 py-1">
        <div style="cursor: pointer">
          <i class="fa fa-search" style="color: #6d6b6d" aria-hidden="true"></i>
        </div>
        <input type="text" class="mx-2 flex-grow-1" v-model="searchProduct" placeholder="Search for Products" />
      </div>
      <!-- <div class="d-flex col-11 p-0 w-100 heading-input-search">
        <label for="search-collection" class="font-weight-bold">Search</label>
        <div class="collectionSearchInput w-100 px-0 ml-2">
          <input id="search-collection" class="w-100" type="search" name="search-VendorProducts" placeholder="Enter Product"
            v-model=searchProduct />
        </div>
      </div> -->

    </div>
    <div class="w-100 p-3 collectionBox" v-if=isLoadingProducts style="border:none">
      <div class="skel">
        <div class="mb-2 py-1" v-for="index in 5" :Key="index">
          <div class="d-flex  align-items-center">
            <b-skeleton type="avatar" animation="throb"></b-skeleton>
            <b-skeleton type="input" class="ml-2 w-86" animation="throb"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="w-100 p-3 collectionBox" v-if="(!areNotAvailableProducts) && getProducts.length > 0" style="border:none">
      <div :class="['mb-2 py-1,collection-row']" v-for="collection in getProducts" :key="collection.id">
        <div class="d-flex align-items-center collection">
          <div class="d-flex col-8 px-0 align-items-center">
            <div class="">

            </div>
            <div class="img ml-2">
              <div v-if="checkFileType(collection.image) == 'video'">
                <video ref="videoRef" :src="`${collection.image}`" alt="" class="img-thumbnail mr-2" style="height: 50px; width: 50px; border-radius: 50%; object-fit: cover;" controls></video>
              </div>
              <LazyImage :src="collectionImage(collection.image)" :key="1" alt="" class=" collection-image" />
            </div>
            <div class="singleProduct d-flex align-items-center pl-2">
              <p class="title text-left">
                {{ collection.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 p-3 collectionBox" v-if="!this.isLoadingProducts && VendorProducts.length > 0 && getProducts <= 0" style="border:none">
      <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
        <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="searchProduct=''"> <i
            class="fa-solid fa-arrow-rotate-right"></i></div>
        <h5>No VendorProducts were found matching your filter </h5>
      </div>
    </div>
  </section>
</template>
<script>


export default {
  props: {
    VendorProducts: Array,
    selected: Object,
    isLoadingProducts: Boolean
  },
  computed: {
    areNotAvailableProducts() {
      return this.isLoadingProducts == false && this.VendorProducts.length <= 0 ? true : false
    },
  

    getProducts() {
      return this.VendorProducts.filter(item => {
        return (item.title.trim().toLowerCase().includes(this.searchProduct.trim().toLowerCase()))
      })

    }
  },
  watch: {
 
  },
  data() {
    return {
      skeletonCount: 5,
      searchProduct: '',
      isSelectAll: true,
      showResetBtn: false,
     
    }
  },

}
</script>
<style >
.collection-image {
  height: 50px;
  width: 50px;
  /* border: 4px solid rgb(255, 255, 255);  */
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.collection-icon {
  color: #4d1b7e;

}

.collection-icon:hover {
  color: black;
}

.collectionSearchInput:focus-within {
  /* background: #9BE300!important; */
  /* width: 250px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-align: center; */
  outline: 1px solid #4d1b7e;

}

.collectionSearchInput:focus-within i {
  /* background: #9BE300!important; */
  /* width: 250px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-align: center; */
  color: #4d1b7e !important;

}

.collectionSearchInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.collectionSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.collectionSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.collectionBox {
  height: 300px;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.collectionBox .collection {
  border-radius: 6px;
}

.collectionBox .collection:hover {
  background: #b3afb614;
}

.variant-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none
}

.selectedProduct .collection:hover .variant-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}

.selectedProduct .collection:hover .variant-btn .variant-text {
  color: #4d1b7e !important;
}

.collection:hover .variant-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}
.back-button i{
  transition: 0.5s ease all;
}
.back-button:active{
  color:#4d1b7e
}
.back-button:hover i{
 
  transform: translateX(-2px);
}
.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>